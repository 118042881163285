<template>
  <PageSection
    class="Account AccountSettings"
    :page-title="$t('pages.settings.company.account.organisation_settings')"
    :page-tooltip="$t('pages.settings.company.account.sidebar_description')"
  >
    <form class="columns" @submit.prevent="saveOrganisation">
      <div class="column">
        <div class="field is-full-height is-flex is-aligned-middle is-aligned-center">
          <CroppieWrapper
            ref="logo"
            v-model="form.logo"
            :settings="croppieSettings"
            :crop-options="croppieSettings.cropOptions"
            :min-width="120"
            :min-height="120"
            :enforce-boundary="false"
            identifier="logo"
            is-circle
            text-size="7"
            entity="OrganisationsLogo"
          >
            {{ $t('forms.upload_logo') }}
          </CroppieWrapper>
        </div>
      </div>

      <div class="column">
        <FormField
          :validator="$v.form.name"
          :label="$t('forms.organisation_name')"
        >
          <input
            v-model="form.name"
            :placeholder="$t('pages.settings.company.account.organisation_name_placeholder')"
            class="input"
            type="text"
            name="organisation_name"
            @input="$v.form.name.$touch()"
          >
        </FormField>

        <FormField
          :validator="$v.form.country"
          :label="$t('forms.country')"
        >
          <CountryPicker
            v-model="form.country"
            :placeholder="$t('pages.settings.company.account.choose_organisational_country')"
            popper-class="Account__countryModal"
          />
        </FormField>

        <FormField
          :validator="$v.form.currency"
          :label="$t('general.currency')"
        >
          <CurrencyPicker
            v-model="form.currency"
            :placeholder="$t('pages.settings.company.account.choose_organisational_currency')"
            popper-class="Account__currencyModal"
          />
        </FormField>

        <FormField
          :validator="$v.form.vatNumber"
          :label="$t('forms.vat_number')"
        >
          <input
            v-model="form.vatNumber"
            :placeholder="$t('forms.vat_number')"
            class="input"
            type="text"
            name="vat_number"
            @input="$v.form.vatNumber.$touch()"
          >
        </FormField>

        <FormField
          :validator="$v.form.lockout_time"
          :label="$t('forms.lockout_time')"
        >
          <LockoutTimePicker v-model="form.lockout_time" />
        </FormField>
      </div>
    </form>

    <div class="has-text-right">
      <VButton
        :loading="form.isLoading || isLoading"
        class="is-primary"
        @click.prevent="saveOrganisation"
      >
        {{ $t('pages.settings.company.account.save_organisation') }}
      </VButton>
    </div>
  </PageSection>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import CroppieWrapper from '@hypefactors/shared/js/components/core/CroppieWrapper.vue'
import CountryPicker from '@hypefactors/shared/js/components/core/CountryPicker.vue'
import FormField from '@hypefactors/shared/js/components/forms/FormField.vue'
import { croppieDefaultSettings } from '@hypefactors/shared/js/factories/croppie.js'

import {
  createOrganisationForm,
  createOrganisationFormRequest,
  prepareOrganisationFormObject
} from '@/forms/OrganisationForm.js'
import PageSection from '@/components/core/PageSection.vue'
import CurrencyPicker from '@/components/core/CurrencyPicker.vue'
import LockoutTimePicker from '@/components/forms/LockoutTimePicker.vue'

export default {
  components: {
    VButton,
    CroppieWrapper,
    FormField,
    CountryPicker,
    CurrencyPicker,
    PageSection,
    LockoutTimePicker
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  data () {
    return {
      isLoading: false,
      form: createOrganisationForm(),
      croppieSettings: croppieDefaultSettings(120, 120)
    }
  },

  computed: {
    ...mapGetters(['firstOrganisation'])
  },

  mounted () {
    this.mergeOrganisation()
  },

  methods: {
    ...mapActions(['fetchUserOrganisationsRequest']),

    mergeOrganisation () {
      this.form.merge(prepareOrganisationFormObject(this.firstOrganisation))
    },

    async saveOrganisation () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.isLoading = true

      try {
        this.form.setPayloadTransformer(createOrganisationFormRequest)

        await this.$refs.logo.uploadImage()

        await this.form.put(`organisations/${this.firstOrganisation.id}`)

        await this.fetchUserOrganisationsRequest()

        this.$notify.success(this.$t('success.organisation_updated', { organisation: this.firstOrganisation.name }))
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
