<template>
  <basic-select
    v-model="valueLocal"
    class="LockoutTimePicker"
    :values="lockoutTimes"
    key-prop="value"
    label-prop="label"
    value-prop="value"
  />
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module LockoutTimePicker
 */
export default {
  name: 'LockoutTimePicker',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    valueLocal: generateComputedSetterWithEmit('value', 'input'),
    lockoutTimes () {
      return [0, 15, 30, 60].map((value) => ({
        label: this.$t('components.lockout_time_picker.choices.' + value),
        value
      }))
    }
  }
}
</script>
